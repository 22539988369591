<template>
  <div class="q-pa-md">

    <q-form @submit="addUser()" class="q-gutter-md">
        <div v-if="relogin" class="col row justify-center">
            <p style="color: red">{{ $t("login.relogin") }}</p>
        </div>
        <div class="col row justify-center">
            <div style="width: 250px">
                <q-input v-model="username" stack-label :label="$t('login.username') + '(*)'"/>
            </div>
        </div>
        <div class="col row justify-center">
            <div style="width: 250px">
                <q-input v-model="remark" stack-label :label="$t('login.remark') + '(*)'"/>
            </div>
        </div>
        <div class="col row justify-center">
            <div style="width: 250px">
                <q-input v-model="password" stack-label :label="$t('login.password') + '(*)'" type="password" />
            </div>
        </div>
        <div style="height: 20px"></div>
        <div class="col row justify-center">
            <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.cancel')" color="secondary" v-on:click="$router.push({name: 'ManagerHome'})"/>
            <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.ok')" type="submit" color="secondary" v-on:click="addUser()" />
        </div>
    </q-form>
  </div>
  <Alert v-if="alert_show" :messages="alert_messages" :key="alert_messages"></Alert>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
    components: {Alert},
    data () {
        return {
            username: '',
            remark: '',
            password: '',

            relogin: false,

            alert_show: false,
            alert_messages: [],
        }
    },
    methods: {
        alert(messages) {
            this.alert_messages = messages;
            this.alert_show = true;
        },

        async addUser() {
            let result = this.$valid_password(this.password);
            console.log(result);
            if (result.length == 0) {
                let response = await this.$fetch("/api/v1/user", {
                    body: JSON.stringify({
                        'username': this.username,
                        'password': this.password,
                        'last_name': this.remark
                    }),
                    method: "post"
                });            
                if (response.status == 200) {
                    this.$router.push({name: 'ManagerHome'});
                } else if (response.status == 409) {
                    this.alert([this.$t('app.user_exist')]);
                } else {
                    this.alert([this.$t('error.retry')]);
                }
            }
            else {
                let messages = [];
                for (let item of result) {
                    messages.push(item.message);
                }
                this.alert(messages);
            }

        } 
    }
}
</script>
